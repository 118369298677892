@import  "../../config/variables.scss";
.login{
    padding: 10rem 0 5rem;
    background-color: $black_third;
    form{
        margin: 0 25rem;
        padding: 3rem;
        color: $white;
        background-color: #343434;
        border-radius: 10px;
        .heading{
            text-align: center;
            padding: 1rem;
            // h1{

            // }
        }
        .formControl{
            margin: 1rem 0;
            input{
                width: 100%;
                color: $white !important;
                border-radius: 10p;
            }
            label{
                color: $white !important;
                opacity: 0.8;
            }
            p{
                display: inline;
                font-size: 12px;
                text-transform: capitalize;
                font-weight: 300;
                text-decoration: underline;
                opacity: 0.4;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
        button{
            color: $white;
            opacity: 0.8;
        }
        .btn{
            border-radius: 5px;
            width: 100%;
            color: $white;
            background-color: $red;
            border: none;
            padding: 0.5rem ;
            opacity: 1;
        }
    }
}