@import "../../config/variables.scss";

.enquiry {
    background-color: $black_pry;
    padding: 5REM 0;
}

form {
    background-color: $black_sec;
    padding: 2rem;
    margin: 0 15rem;

    .heading {
        text-align: center;
        margin-bottom: 2rem;

        h1 {
            color: $pry_color;
            font-size: 36px;
        }
    }

    label {
        color: $white;
        opacity: 0.5;
        border-color: red
    }

    :global {


        .MuiInputBase-input {
            color: $white;
        }

        option {
            background-color: #000;
        }

        .MuiFormLabel-root {
            color: $white !important;

        }
    }

    .box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;

    }

    button {
        color: $white;
        opacity: 0.5;
    }

    .btn {
        margin-top: 1rem;
        border-radius: 5px;
        width: 100%;
        color: $white;
        background-color: $red;
        border: none;
        padding: 0.5rem;
        opacity: 1;
    }

}


@media screen and (max-width: 448px) {
    .enquiry {
        background-color: $black_pry;
        padding: 3REM 0;
    }

    form {
        background-color: $black_sec;
        padding: 1rem;
        margin: 0 0;

        .heading {
            text-align: center;
            margin-bottom: 2rem;

            h1 {
                color: $pry_color;
                font-size: 24px;
            }
        }

        label {
            color: $white;
            opacity: 0.5;
            border-color: red;
            font-size: 12px;
        }

        :global {

            .MuiInputBase-input {
                color: $white;
            }

            option {
                background-color: #000;
            }

            .MuiFormLabel-root {
                color: $white !important;

            }
        }

        .box {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            column-gap: 20px;

        }

        button {
            color: $white;
            opacity: 0.5;
        }

        .btn {
            margin-top: 1rem;
            border-radius: 5px;
            width: 100%;
            color: $white;
            background-color: $red;
            border: none;
            padding: 0.5rem;
            opacity: 1;
        }

    }
}