
@import "../../config/variables.scss";

.thanx{
    background-color: $black_pry;
    padding: 15rem 0;
    display: flex ;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .box{
        background-color: $black_sec;
        padding: 5rem;
        border-radius: 10px;
        .checkIcon {
            animation: scaleUp 2s ease forwards;
            svg{
                color: green;

            }
          }
          
          @keyframes scaleUp {
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
          }
        h1{
            font-size: 36px;
            font-weight: 600;
            padding-bottom: 1rem;
            color: $pry_color;
        }
        p{
            font-size: 18px;
        }
        a{
            font-size: 18px;
            text-decoration: underline;
        }
    }
}


@media screen  and (max-width:448px){
    .thanx{
        background-color: $black_pry;
        padding: 5rem 0;
        display: flex ;
        align-items: center;
        flex-direction: column;
        text-align: center;
        .box{
            background-color: $black_sec;
            padding: 2rem;
            border-radius: 10px;
            .checkIcon {
                animation: scaleUp 2s ease forwards;
                svg{
                    color: green;
    
                }
              }
              
              @keyframes scaleUp {
                from {
                  transform: scale(0);
                }
                to {
                  transform: scale(1);
                }
              }
            h1{
                font-size: 20px;
                font-weight: 600;
                padding-bottom: 1rem;
                color: $pry_color;
            }
            p{
                font-size: 14px;
            }
            a{
                font-size: 12px;
                text-decoration: underline;
            }
        }
    }  
}