@import "../../config/variables.scss";
.heading{
    background-image:radial-gradient(circle at 85.4% 50.8%, rgba(43, 42, 39, 0.708)0%, rgba(43, 42, 39, 0.708) 74.2%), url("../../../public/assets/bannr/banner_2.webp");
    background-position: bottom;
    background-size: cover;
    padding: 5rem 0;
    text-align: center;

    h1{
        text-align: center; 
        font-weight: 700;
    }
    p{
        font-size: 12px ;
        margin: 0;
        padding: 1rem 5rem;
    }
}
.gallery {
    background-color:$black_third ;
    padding: 3rem 0;
    .glry_nav {
        width: 40%;
        // background: url("../../../public/assets/inst (2).jpeg");
        ul{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1rem;
                border-bottom: 1px solid hsla(0, 2%, 75%, 0.424);
                li{
                    margin-bottom: 1rem;
                    a{
                        color: #919191;
                        font-size: 14px;
                        transition: all 0.2s ease-in-out;
                        padding: 0.5rem 1rem ;

                        &:hover{
                            padding: 0.5rem 1rem ;
                            background-color: rgba(255, 0, 0, 0.247);
                            border-radius: 50px;
                            color:$white; 
    
                        }

                    }
                    .active {
                        padding: 0.5rem 1rem ;
                        background-color: rgba(255, 0, 0, 0.247);
                        border-radius: 50px;
                        color:$white; 

                  }
                }

        }
    }
  }
  
  
  .img_body {
    overflow: hidden ;
    .img{
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        img{
            width: 100%;
            transition: all 0.6s ease-in-out;
            border-radius: 10px;
            &:hover{
                transform: scale(1.2);
            }
            
        }
        &:hover img::after{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";
            background-color: #00000074;
            width: 100%;
        }
    }
    /* Add styles for the image body if needed */
  }
  
  .fadeIn {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
  }
  
  .fadeIn img {
    transition: opacity 0.5s ease-in-out; /* Apply the transition to the images */
  }

  @media screen and (max-width:448px) {
    .admission_bnnr{
        height: 30vh;
       h1{
        font-size: 24px;
        padding: 4rem 0;
       }
     
    }
    .gallery {
        background-color:$black_third ;
        padding: 3rem 0;
        .glry_nav {
            width: 100%;
            // background: url("../../../public/assets/inst (2).jpeg");
            ul{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    border-bottom: 1px solid hsla(0, 2%, 75%, 0.424);
                    li{
                        margin-bottom: 1rem;
                        a{
                            color: #919191;
                            font-size: 12px;
                            transition: all 0.2s ease-in-out;
                            padding: 0.5rem 1rem ;
    
                            &:hover{
                                padding: 0.5rem 1rem ;
                                background-color: rgba(255, 0, 0, 0.247);
                                border-radius: 50px;
                                color:$white; 
        
                            }
    
                        }
                        .active {
                            padding: 0.5rem 1rem ;
                            background-color: rgba(255, 0, 0, 0.247);
                            border-radius: 50px;
                            color:$white; 
    
                      }
                    }
    
            }
        }
      }
      
      
      .img_body {
        overflow: hidden ;
        ul{
            display: grid;
            grid-template-columns: repeat(2,1fr) !important;
        }
        .img{
            overflow: hidden;
            border-radius: 10px;
            position: relative;
    
            img{
                width: 100%;
                transition: all 0.6s ease-in-out;
                border-radius: 10px;
                &:hover{
                    transform: scale(1.2);
                }
                
            }
            &:hover img::after{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: "";
                background-color: #00000074;
                width: 100%;
            }
        }
        /* Add styles for the image body if needed */
      }
      
      .fadeIn {
        opacity: 1;
        transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
      }
      
      .fadeIn img {
        transition: opacity 0.5s ease-in-out; /* Apply the transition to the images */
      }
  }