@import "../../config/variables.scss";
.admission_bnnr{
    height: 60vh;
    @include black-overlay;
    img{
        width: 100%;
        height: 100%;
    }
}

.admission{
    padding: 5rem 0;
    background: $black_third;
    .heading{
        text-align: center;
        margin-bottom: 3rem;
        h1{
            color: $pry_color;
        }
    }

    .wrp{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        .contactDetails{

            h1{
                font-size: 36px;
                margin-bottom: 2rem;

            }
            p{
                font-size: 18px;
                opacity: 0.7;

                i{
                    position: absolute;
                    padding-right: 3rem;
                    // left: 0;
                }
                span{
                    margin-left: 2rem;
                }
            }
            .social_mdia{
                display: flex;
                padding-top: 2rem;
                opacity: 0.7;
                li{
                    padding-right: 1rem ;
                }
            }

        }
        iframe{
            width: 600px;
            height: 400px;
            margin-top: 1rem;
        }
    }

    form{
        background-color: $black_sec;
        padding: 2rem ;
        // margin: 0 15rem;
        label{
            color: $white;
            opacity: 0.5;
            border-color: red
        }
        input{
            color: $white;
        }
        .box{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 20px;

        }
        button{
            color: $white;
            opacity: 0.5;
        }

        .btn{
            margin-top: 1rem;
            border-radius: 5px;
            width: 100%;
            color: $white;
            background-color: $red;
            border: none;
            padding: 0.5rem ;
            opacity: 1;
        }

    }
}
@media screen and (max-width:448px) {
    .admission_bnnr{
        height: 100%;
        @include black-overlay;
        img{
            width: 100%;
            height: 100%;
        }
    }
    
    .admission{
        padding: 2rem 0;
        background: $black_third;
        .heading{
            text-align: center;
            margin-bottom: 1rem;
            h1{
                color: $pry_color;
                font-size: 24px;
            }
        }
    
        .wrp{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            align-items: center;
            .contactDetails{
    
                h1{
                    font-size: 18px;
                    margin-bottom: 2rem;
    
                }
                p{
                    font-size: 12px;
                    opacity: 0.7;
    
                    i{
                        position: absolute;
                        padding-right: 0.5rem;
                        // left: 0;
                    }
                    span{
                        margin-left: 1rem;
                    }
                }
                
                .social_mdia{
                    display: flex;
                    padding-top: 1rem;
                    opacity: 0.7;
                    li{
                        padding-right: 1rem ;
                    }
                }
    
            }
            iframe{
                width: 100%;
                margin-top: 1rem;
            }
        }
    }
}