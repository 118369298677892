@import "./config/variables.scss";
// @import url("./components/Navbar/Navbar.module.scss");
*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #fff;
}
body{
  padding-top: 100px;
  // background-color: $bla;
}
@media screen and (max-width:448px) {
  body{
    padding-top: 0;
    // background-color: $bla;
  }
}
h1,h2,h3,h4,h5,h6{
  padding: 0;
  margin: 0;
  font-family: 'Playfair Display', serif;
}
p{
  font-size: 16px;
  font-family: 'Poppins', sans-serif;

}
ul{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}

